const shelters = [
  {
    id: 1,
    nama: "Pengungsian Desa Sumberagung",
    lokasi: "Desa Sumberagung, Kecamatan Turen, Kabupaten Malang",
    alamat: "Jl. Raya Desa Sumberagung No. 1",
    linkMaps: "https://maps.google.com/?q=Desa+Sumberagung",
    kapasitas: 100,
    fasilitas: ["Tempat tidur", "Makanan", "Air bersih", "Kesehatan"],
    nomor: "08123456789",
    gambar: [
      "/images/1.jpg", 
      "/images/2.jpg",
      "/images/3.jpg",
    ],
    tambahan: "Lokasi pengungsian di desa dengan akses mudah",
  },
  {
    id: 2,
    nama: "Pengungsian Desa Sidomulyo",
    lokasi: "Desa Sidomulyo, Kecamatan Candi, Kabupaten Sidoarjo",
    alamat: "Jl. Raya Desa Sidomulyo No. 2",
    linkMaps: "https://maps.google.com/?q=Desa+Sidomulyo",
    kapasitas: 150,
    fasilitas: ["Tempat tidur", "Makanan", "Air bersih", "Kesehatan"],
    nomor: "08987654321",
    gambar: [
      "/images/4.jpg", 
      "/images/3.jpg",
      "/images/1.jpg",
    ],
    tambahan: "Lokasi pengungsian di desa dengan fasilitas lengkap",
  },
  {
    id: 3,
    nama: "Pengungsian Desa Gunungkidul",
    lokasi: "Desa Gunungkidul, Kecamatan Pangandaran, Kabupaten Pangandaran",
    alamat: "Jl. Raya Desa Gunungkidul No. 3",
    linkMaps: "https://maps.google.com/?q=Desa+Gunungkidul",
    kapasitas: 120,
    fasilitas: ["Tempat tidur", "Makanan", "Air bersih"],
    nomor: "08765432109",
    gambar: [
      "/images/4.jpg", 
      "/images/3.jpg",
      "/images/1.jpg",
    ],
    tambahan: "Lokasi pengungsian di desa dengan pemandangan alam yang indah",
  },
  {
    id: 4,
    nama: "Pengungsian Desa Mekarsari",
    lokasi: "Desa Mekarsari, Kecamatan Parung, Kabupaten Bogor",
    alamat: "Jl. Raya Desa Mekarsari No. 4",
    linkMaps: "https://maps.google.com/?q=Desa+Mekarsari",
    kapasitas: 180,
    fasilitas: ["Tempat tidur", "Makanan", "Kesehatan"],
    nomor: "08543210987",
    gambar: [
      "/images/4.jpg", 
      "/images/3.jpg",
      "/images/1.jpg",
    ],
    tambahan: "Lokasi pengungsian di desa dengan akses transportasi yang mudah",
  },
  {
    id: 5,
    nama: "Pengungsian Desa Nusantara",
    lokasi: "Desa Nusantara, Kecamatan Kuta, Kabupaten Badung",
    alamat: "Jl. Raya Desa Nusantara No. 5",
    linkMaps: "https://maps.google.com/?q=Desa+Nusantara",
    kapasitas: 90,
    fasilitas: ["Tempat tidur", "Air bersih"],
    nomor: "08432109876",
    gambar: [
      "/images/4.jpg", 
      "/images/3.jpg",
      "/images/1.jpg",
    ],
    tambahan: "Lokasi pengungsian di desa dengan suasana tenang",
  },
  {
    id: 6,
    nama: "Pengungsian Kota Sentosa",
    lokasi: "Kota Sentosa, Kecamatan Sentosa, Kabupaten Sentosa",
    alamat: "Jl. Raya Kota Sentosa No. 6",
    linkMaps: "https://maps.google.com/?q=Kota+Sentosa",
    kapasitas: 200,
    fasilitas: ["Tempat tidur", "Makanan", "Air bersih", "Kesehatan"],
    nomor: "08210987654",
    gambar: [
      "/images/4.jpg", 
      "/images/3.jpg",
      "/images/1.jpg",
    ],
    tambahan: "Lokasi pengungsian di kota dengan fasilitas terbaik",
  },
  {
    id: 7,
    nama: "Pengungsian Desa Rancamaya",
    lokasi: "Desa Rancamaya, Kecamatan Cibubur, Kabupaten Bogor",
    alamat: "Jl. Raya Desa Rancamaya No. 7",
    linkMaps: "https://maps.google.com/?q=Desa+Rancamaya",
    kapasitas: 110,
    fasilitas: ["Tempat tidur", "Makanan", "Air bersih", "Kesehatan"],
    nomor: "08109876543",
    gambar: [
      "/images/4.jpg", 
      "/images/3.jpg",
      "/images/1.jpg",
    ],
    tambahan: "Lokasi pengungsian di desa dengan akses yang mudah dijangkau",
  },
  {
    id: 8,
    nama: "Pengungsian Kota Mawar",
    lokasi: "Kota Mawar, Kecamatan Mawar, Kabupaten Mawar",
    alamat: "Jl. Raya Kota Mawar No. 8",
    linkMaps: "https://maps.google.com/?q=Kota+Mawar",
    kapasitas: 170,
    fasilitas: ["Tempat tidur", "Makanan", "Kesehatan"],
    nomor: "08765432109",
    gambar: [
      "/images/4.jpg", 
      "/images/3.jpg",
      "/images/1.jpg",
    ],
    tambahan: "Lokasi pengungsian di kota dengan pelayanan yang ramah",
  },
  {
    id: 9,
    nama: "Pengungsian Desa Gunungsari",
    lokasi: "Desa Gunungsari, Kecamatan Singosari, Kabupaten Malang",
    alamat: "Jl. Raya Desa Gunungsari No. 9",
    linkMaps: "https://maps.google.com/?q=Desa+Gunungsari",
    kapasitas: 130,
    fasilitas: ["Tempat tidur", "Makanan", "Air bersih"],
    nomor: "08432109876",
    gambar: [
      "/images/4.jpg", 
      "/images/3.jpg",
      "/images/1.jpg",
    ],
    tambahan: "Lokasi pengungsian di desa dengan suasana alam yang asri",
  },
  {
    id: 10,
    nama: "Pengungsian Kota Bambu",
    lokasi: "Kota Bambu, Kecamatan Kuningan, Kabupaten Kuningan",
    alamat: "Jl. Raya Kota Bambu No. 10",
    linkMaps: "https://maps.google.com/?q=Kota+Bambu",
    kapasitas: 160,
    fasilitas: ["Tempat tidur", "Makanan", "Air bersih", "Kesehatan"],
    nomor: "08123456789",
    gambar: [
      "https://via.placeholder.com/400x300.png?text=Bambu+1",
      "https://via.placeholder.com/400x300.png?text=Bambu+2"
    ],
    tambahan: "Lokasi pengungsian di kota dengan fasilitas yang lengkap",
  },
];

export default shelters;
